<template>
  <div id="app">
    <Navbar v-if="navVisible" />
    <transition>
      <router-view></router-view>
    </transition>
    <Footer v-if="footerVisible" />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  watch: {
    $route(to) {
      document.title = to.meta.title
        ? "리뷰마인드" + to.meta.title
        : "리뷰마인드";
    },
  },
  computed: {
    ...mapState(["navVisible", "footerVisible"]),
  },
};
</script>
<style>
/* .topic-left-enter,
.topic-left-leave-to,
.topic-right-enter,
.topic-right-leave-to {
  opacity: 0;
} */

.slide-left-leave-active,
.slide-left-enter-active {
  transition: all 0.5s;
}
.slide-left-enter {
  transform: translate(100%, 0);
}
.slide-left-leave-to {
  transform: translate(-100%, 0);
}

.slide-right-leave-active,
.slide-right-enter-active {
  transition: all 0.5s;
}
.slide-right-enter {
  transform: translate(-100%, 0);
}
.slide-right-leave-to {
  transform: translate(100%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
