<template>
  <div>
    <div class="top-bar">
      <!-- <input type="text" placeholder="검색" /> -->
      <!-- <span>{{ userInfo.username }}</span> -->
      <el-dropdown trigger="click">
        <!-- <span class="el-dropdown-link">
          <img src="@/assets/images/reviewmind/profile_sample.jpg" alt="" />
        </span> -->
        <router-link to
          ><span>{{ userInfo.username }}</span></router-link
        >
        <el-dropdown-menu slot="dropdown">
          <router-link to="/mypage">
            <el-dropdown-item icon="el-icon-user">{{
              $t("mypage")
            }}</el-dropdown-item>
          </router-link>
          <el-dropdown-item icon="el-icon-coin">{{
            $t("payment")
          }}</el-dropdown-item>
          <router-link v-show="isAdmin" to="/admin/users">
            <el-dropdown-item icon="el-icon-user"
              >관리자</el-dropdown-item
            ></router-link
          >
          <router-link to="/alarm">
            <el-dropdown-item icon="el-icon-bell">{{
              $t("noti")
            }}</el-dropdown-item></router-link
          >
          <a @click="handleLog">
            <el-dropdown-item icon="el-icon-unlock">{{
              $t("logout")
            }}</el-dropdown-item></a
          >
        </el-dropdown-menu>
      </el-dropdown>
      <a class="popBtn">
        <!-- <el-popover
          placement="bottom-end"
          width="170"
          trigger="manual"
          content="이곳을 클릭해서 제품을 등록해주세요!"
          v-model="popover"
        > -->
        <el-button slot="reference" @click="handlePop" class="top_btn"
          ><span class="material-icons"> settings </span></el-button
        >
        <!-- </el-popover> -->
      </a>
      <router-link to="/">
        <button class="top_btn">
          <span class="material-icons"> home </span>
        </button>
      </router-link>
      <!-- <a @click="handlePop" class="popBtn" style="position:relative">
        <button class="top_btn">
          <span class="material-icons"> settings </span>
        </button>
      </a> -->

      <button class="top_btn" @click="langChange()">
        {{ currentLang === "ko" ? "EN" : "KR" }}
      </button>
    </div>

    <transition name="fade">
      <div v-if="visible" class="dim on">
        <Product-Form @openModal="openModal" />
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ProductForm from "@/components/pop/ProductForm";
import lang from "@/mixins/lang";
import { eventBus } from "@/main";
export default {
  mixins: [lang],
  components: {
    ProductForm,
  },
  data() {
    return {
      visible: false,
      popover: this.asin ? false : true,
    };
  },
  mounted() {
    eventBus.$on("closeModal", () => {
      this.closeModal();
    });
  },
  computed: {
    ...mapState(["userInfo", "isLogin", "currentLang", "isAdmin", "asin"]),
  },
  methods: {
    closeModal() {
      this.visible = false;
    },
    openModal() {
      this.visible = true;
    },
    handlePop() {
      this.popover = false;
      this.visible = true;
    },
    handleLog() {
      if (this.isLogin == true) {
        let result = confirm("로그아웃 하시겠습니까?");
        if (result) {
          if (this.userInfo.type == "KAKAO") {
            if (!window.Kakao.Auth.getAccessToken()) {
              return alert(
                "로그아웃시 에러가 발생하였습니다. 다시 시도해주세요"
              );
            } else {
              window.Kakao.Auth.logout(() => {
                this.$store.dispatch("LOGOUT");
              });
            }
          } else if (this.userInfo.type == "GOOGLE") {
            // window.gapi.auth.getToken();
            let auth2 = window.gapi.auth2.getAuthInstance();

            auth2.signOut().then(() => {
              this.$store.dispatch("LOGOUT");
            });
          } else {
            this.$store.dispatch("LOGOUT");
          }
        }
      } else {
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>
