<template>
  <div class="productForm modal">
    <div class="modal_cover"></div>
    <div class="modal_content">
      <div class="m_header">
        <div>{{ $t("register_goods") }}</div>
        <button class="close" @click="closeModal()">
          <span class="material-icons">close</span>
        </button>
      </div>

      <div class="m_body">
        <div class="buttonWrap">
          <button :class="{ active: ownVisible }" @click="productTab('own')">
            {{ $t("own_goods") }}
          </button>
          <button
            :class="{ active: !ownVisible }"
            @click="productTab('competitor')"
          >
            {{ $t("competitor_goods") }}
          </button>
        </div>

        <div class="product-list">
          <div v-if="ownVisible" class="productAdd">
            <label>
              <input
                type="radio"
                name="type"
                v-model="type"
                id="amazon"
                value="amazon"
                checked
              />
              {{ $t("amazon") }}
            </label>
            <label>
              <input
                type="radio"
                name="type"
                v-model="type"
                id="domestic"
                value="naver"
              />
              {{ $t("domestic") }}
            </label>

            <ul>
              <li>
                <div v-if="type == 'amazon'">
                  <h2>ASIN</h2>
                  <input
                    type="text"
                    v-model="asin"
                    alt="product name"
                    placeholder="ASIN"
                  />
                </div>
                <div v-else>
                  <h2>URL</h2>
                  <input
                    type="text"
                    v-model="url"
                    alt="product name"
                    placeholder="www.example.com"
                  />
                </div>
                <h2>{{ $t("name") }}</h2>
                <input
                  type="text"
                  v-model="productName"
                  placeholder="제품명 (영어로만 표기해 주세요)"
                />
              </li>
            </ul>
            <button
              v-if="productId"
              class="btn delete"
              @click="removeProduct(productId)"
            >
              {{ $t("delete") }}
            </button>
            <el-button
              v-else
              class="btn"
              @click="submit()"
              :loading="isLoading"
              :disabled="isLoading"
              >{{ $t("register_goods") }}</el-button
            >
            <!-- <button class="btn" @click="submit()">
              {{ $t("register_goods") }}
            </button>-->
          </div>

          <!-- <div v-if="productList.length == 0" class="productAdd"> -->
          <div v-else class="productAdd competitor">
            <div>
              <ul>
                <li v-for="(data, i) in competitorList" :key="i" class="flexB">
                  <div>
                    <p v-if="data.type == 'amazon'" class="flexL">
                      <span>ASIN</span>
                      {{ data.asin }}
                    </p>
                    <p v-else class="flexL">
                      <span>URL</span>
                      {{ data.url }}
                    </p>
                    <!-- | -->
                    <div class="flexL">
                      <p>
                        <span>PRODUCT</span>
                        {{ data.productName }}
                      </p>
                    </div>
                  </div>
                  <button @click="removeCompetitorProduct(data._id)">
                    <span class="material-icons">delete_forever</span>
                  </button>
                </li>
              </ul>
            </div>
            <!-- <p>
              <button @click="handleAddCompetitor()">{{ $t("add") }}</button>
            </p>-->
            <ul :class="{ comptAdd: competitorList.length > 0 }">
              <li>
                <!-- <button @click="handleDeleteCompetitor(i)">
                  {{ $t("delete") }}
                </button>-->
                <div v-if="type == 'amazon'">
                  <h2>ASIN</h2>
                  <input
                    type="text"
                    v-model="competitorAsin"
                    alt="product name"
                    placeholder="ASIN"
                  />
                </div>
                <div v-else>
                  <h2>URL</h2>
                  <input
                    type="text"
                    v-model="competitorUrl"
                    alt="product name"
                    placeholder="www.example.com"
                  />
                </div>
                <h2>{{ $t("name") }}</h2>
                <input
                  type="text"
                  v-model="competitorProductName"
                  placeholder="제품명"
                />
              </li>
            </ul>
            <!-- <button class="btn" @click="submitForCompetitorData()" :disabled="isLoading">
              {{ $t("register_comp_goods") }}
            </button>-->
            <el-button
              class="btn"
              @click="submit('competitor')"
              :loading="isLoading"
              :disabled="isLoading"
              >{{ $t("register_comp_goods") }}</el-button
            >
          </div>
          <!-- <div class="m_footer">
            <button class="btn" @click="closeModal()">확인</button>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  createProduct,
  fetchProductList,
  deleteProduct,
  deleteCompetitorProduct,
} from "@/api/product";
import { createModelData, fetchReviewCount } from "@/api/review";
import { createPointDeduction, verifyPointDeduction } from "@/api/index";
import { eventBus } from "@/main";
import { mapState } from "vuex";

export default {
  data() {
    return {
      visible: false,
      type: "amazon",
      asin: "",
      url: "",
      productName: "",
      competitorAsin: "",
      competitorUrl: "",
      competitorProductName: "",
      competitorList: [],
      CompetitorProduct: [],
      produtNameList: [],
      productUrlList: [],
      productList: [],
      urlList: [], //저장을 위한 제품 url 배열
      comUrlList: [], //저장을 위한 경쟁사 제품 url 배열
      index: 0,
      ownVisible: true,
      isLoading: false,
      deduction: 0,
      productId: "",
    };
  },
  mounted() {
    this.getProductInfo();
  },
  computed: {
    ...mapState(["userInfo", "point"]),
  },
  methods: {
    closeModal() {
      eventBus.$emit("closeModal", true);
    },
    openModal() {
      this.$emit("openModal");
    },
    productTab(tab) {
      this.isLoading = false;
      if (tab == "own") {
        this.ownVisible = true;
      } else {
        this.ownVisible = false;
      }
    },
    removeProduct(_id) {
      let result = confirm(
        "자사 제품을 삭제하면 데이터도 삭제가 됩니다. 삭제 하시겠습니까?"
      );
      if (result == false) {
        return;
      }
      deleteProduct(_id).then((res) => {
        if (res.data.status == 200) {
          this.closeModal();
          window.document.location.href = window.document.location;
        } else {
          return alert(res.data.message);
        }
      });
    },
    removeCompetitorProduct(_id) {
      let result = confirm("경쟁사 제품 정보를 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }
      deleteCompetitorProduct(_id).then((res) => {
        if (res.data.status == 200) {
          this.getProductInfo();
          return alert("정상적으로 삭제 되었습니다.");
          // this.closeModal();
          // window.document.location.href = window.document.location;
        } else {
          return alert(res.data.message);
        }
      });
    },

    getProductInfo() {
      this.competitorList = [];
      fetchProductList().then((res) => {
        if (res.data.status == 200) {
          this.productList = res.data.data;
          this.productList.forEach((item) => {
            if (item.isCompetitor == true) {
              this.competitorList.push(item);
            } else {
              this.type = item.type;
              this.asin = item.asin;
              this.url = item.url;
              this.productName = item.productName;
              this.productId = item._id;
            }
          });
        }
      });
    },
    Loading() {
      this.$store.dispatch("LOADING", true);
    },
    async submit(flag) {
      if (flag == "competitor") {
        if (this.type == "amazon") {
          if (this.competitorAsin == "") {
            return alert("asin을 입력해 주세요");
          }
        } else {
          if (this.competitorUrl == "") {
            return alert("주소 정보를 입력해주세요.");
          }
        }
        if (this.competitorProductName == "") {
          return alert("제품명을 입력해 주세요");
        }
      } else {
        if (this.type == "amazon") {
          if (this.asin == "") {
            return alert("asin을 입력해 주세요");
          }
        } else {
          if (this.url == "") {
            return alert("주소 정보를 입력해주세요.");
          }
        }
        if (this.productName == "") {
          return alert("제품명을 입력해 주세요");
        }
      }

      if (flag == "competitor" && this.competitorList.length == 3) {
        return alert("제품은 최대 3개까지 등록 가능합니다.");
      }
      let data;
      if (flag == "competitor") {
        data = {
          type: this.type,
          asin: this.competitorAsin,
          url: this.competitorUrl,
          productName: this.competitorProductName,
          isCompetitor: true,
        };
      } else {
        data = {
          type: this.type,
          asin: this.asin,
          url: this.url,
          productName: this.productName,
          isCompetitor: false,
        };
      }
      this.isLoading = true;

      //리뷰 개수를 확인하고 차감될 포인트를 설정
      let response = await fetchReviewCount(data);
      this.deduction = response.data.count;
      if (response.data.status == 500) {
        this.isLoading = false;
        return alert("서버 에러가 발생하였습니다. 운영자에게 문의 하세요.");
      }

      let result = confirm(
        `${response.data.count} 포인트(P)가 차감됩니다. 진행 하시겠습니까?`
      );
      if (result == false) {
        this.isLoading = false;
        return;
      }
      this.isLoading = false;

      //유저가 보유한 포인트가 차감될 포인트보다 많은지 검증
      let pointData = {
        point: -this.deduction,
      };
      let pointResult = await verifyPointDeduction(pointData);
      if (pointResult.data.status == 407) {
        this.$store.dispatch("LOADING", false);
        return alert("포인트가 부족합니다. 포인트 충전후 이용해주세요.");
      }
      this.$store.dispatch("LOADING", true);

      createProduct(data).then(async (res) => {
        if (res.data.status == 200) {
          alert(
            "정상적으로 제품을 등록 하였습니다. 데이터를 불러오는데 몇분의 시간이 거리오니 양해바랍니다."
          );
          data.productId = res.data.productId;
          try {
            await this.getModelData(data);
          } catch (err) {
            alert("제품을 다시 등록해주세요.");
          }
        } else {
          await deleteProduct(res.data.productId);
          alert(res.data.message);
        }
        this.$store.dispatch("LOADING", false);
      });
    },

    //경쟁사 모델 등록
    // async getCompetitorModelData(data) {
    //   window.console.log("getCompetitorModelData data", data);
    //   createModelData(data).then(async (res) => {
    //     if (res.data.status == 200) {
    //       /**
    //        * 여기서 포인트 차감
    //        */
    //       let data = {
    //         point: -this.deduction,
    //       };
    //       try {
    //         await createPointDeduction(data);
    //         window.document.location.href = window.document.location;
    //       } catch (err) {
    //         window.console.log("1err", err);
    //         await deleteProduct(this.productId);
    //         return alert(
    //           "데이터 처리중 오류가 발생하였습니다 다시 시도해주세요."
    //         );
    //       }
    //     } else if (res.data.status == 404) {
    //       return alert(
    //         "잘못된 정보를 입력하였습니다. 올바른 정보를 다시 입력해 주세요."
    //       );
    //     } else {
    //       return alert(
    //         "데이터 처리중 오류가 발생하였습니다 다시 시도해주세요."
    //       );
    //     }

    //   });
    // },
    async getModelData(data) {
      let res = await createModelData(data);
      if (res.data.status == 200) {
        /**
         * 여기서 포인트 차감
         */
        let input = {
          point: -this.deduction,
        };
        try {
          await createPointDeduction(input);
          this.closeModal();
          window.document.location.href = window.document.location;
        } catch (err) {
          alert("데이터 처리중 오류가 발생하였습니다 다시 시도해주세요.");
        }
      } else {
        await deleteProduct(data.productId);
        alert(res.data.message);
      }
    },
  },
};
</script>
