import Cookies from "js-cookie";

const mutations = {
  LOGIN(state, data) {
    state.isLogin = true;
    state.userInfo = data;
    localStorage.setItem("accesstoken", data.accesstoken);
    // window.location.href = "/";
  },
  LOGOUT(state) {
    state.isLogin = false;
    state.isAdmin = false;
    state.userInfo = "";
    localStorage.removeItem("accesstoken");
  },
  LOCATION(state, data) {
    state.location = data;
  },
  ISPAID(state, data) {
    state.isPaid = data;
  },
  SET_NAVBAR(state, data) {
    state.navVisible = data.visible;
  },
  SET_FOOTER(state, data) {
    state.footerVisible = data.visible;
  },
  REVIEWDATA(state, data) {
    state.reviewData = data;
  },
  SET_PRODUCT(state, data) {
    state.url = data.url;
    state.asin = data.asin;
    state.type = data.type;
    state.productName = data.productName;
    // state.competitorList = data.competitorList;
  },
  LOADING(state, data) {
    state.isLoading = data;
  },
  GNB_MENU(state, data) {
    state.gnbMenu = data;
  },
  DASHBOARD_MENU(state, data) {
    state.dashboardMenu = data;
  },
  langChange(state, data) {
    state.currentLang = data;
    Cookies.set("language", data);
  },
  SET_USER_INFO(state, data) {
    state.userInfo.id = data.id;
    state.userInfo._id = data._id;

    if (data.isAdmin) {
      state.isAdmin = data.isAdmin;
    }

    if (data.email) {
      state.userInfo.email = data.email;
    }

    if (data.type) {
      state.userInfo.type = data.type;
    }

    if (data.mobileNo) {
      state.userInfo.mobileNo = data.mobileNo;
    }

    if (data.username) {
      state.userInfo.username = data.username;
    }

    if (data.avatar) {
      state.userInfo.avatar = data.avatar;
    }
    if (data.point) {
      state.userInfo.point = data.point;
    }
  },
  VIEW_TERM(state, data) {
    state.term = data;
  },
  SET_ASIN(state, data) {
    state.asin = data;
  },
  SET_URL(state, data) {
    state.url = data;
  }
};
export default mutations;
