import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);

// const requireAuth = () => (to, from, next) => {
//   let accesstoken = localStorage.getItem("accesstoken");
//   if (accesstoken == null || accesstoken == undefined) {
//     alert("로그인 후 사용가능 합니다.");
//     next("/");
//   } else {
//     next();
//   }
// };
const requireDashBoard = () => (to, from, next) => {
  if (store.state.isPaid || store.state.isAdmin) {
    next();
  } else {
    next();
  }
};

const redirect = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");
  if (accesstoken) {
    alert("이미 로그인 하였습니다.");
    next("/");
  } else {
    next();
  }
};

const requireAdmin = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");
  if (accesstoken && store.state.isAdmin == true) {
    next();
  } else {
    next("/");
  }
};

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("../views/Pricingpage.vue")
  },
  {
    path: "/watchDemo",
    name: "watchdemo",
    component: () => import("../views/WatchDemo.vue")
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/dashboard/Container.vue"),
    //beforeEnter: requireAuth(),
    beforeEnter: requireDashBoard(),
    children: [
      {
        path: "reviewmind",
        name: "reviewmind",
        component: () => import("../views/dashboard/Reviewmind.vue")
      },
      {
        path: "reviewmind2",
        name: "reviewmind2",
        component: () => import("../views/dashboard/Reviewmind2.vue")
      },
      {
        path: "reviewmind3",
        name: "reviewmind3",
        component: () => import("../views/dashboard/Reviewmind3.vue")
      },
      {
        path: "reviewmind4",
        name: "reviewmind4",
        component: () => import("../views/dashboard/Reviewmind4.vue")
      },
      {
        path: "reviewmind5",
        name: "reviewmind5",
        component: () => import("../views/dashboard/Reviewmind5.vue")
      },
      {
        path: "reviewmind6",
        name: "reviewmind6",
        component: () => import("../views/dashboard/Reviewmind6.vue")
      },
      {
        path: "reviewmind7",
        name: "reviewmind7",
        component: () => import("../views/dashboard/Reviewmind7.vue")
      }
    ]
  },
  // {
  //   path: "/reviewmind2",
  //   name: "reviewmind2",
  //   component: () => import("../views/reviewmind2.vue"),
  // },
  // {
  //   path: "/reviewmind3",
  //   name: "reviewmind3",
  //   component: () => import("../views/reviewmind3.vue"),
  // },
  // {
  //   path: "/reviewmind4",
  //   name: "reviewmind4",
  //   component: () => import("../views/reviewmind4.vue"),
  // },
  // {
  //   path: "/reviewmind5",
  //   name: "reviewmind5",
  //   component: () => import("../views/reviewmind5.vue"),
  // },
  // {
  //   path: "/reviewmind6",
  //   name: "reviewmind6",
  //   component: () => import("../views/reviewmind6.vue"),
  // },
  // {
  //   path: "/reviewmind7",
  //   name: "reviewmind7",
  //   component: () => import("../views/reviewmind7.vue"),
  // },
  {
    path: "/alarm",
    name: "alarm",
    component: () => import("../views/alarm/AlarmList.vue")
    // beforeenter: requireauth(),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin/Admin.vue"),
    beforeEnter: requireAdmin(),
    children: [
      {
        path: "users",
        name: "users",
        component: () => import("../views/admin/Users.vue")
      },
      {
        path: "usersDetail",
        name: "userDetail",
        component: () => import("../views/admin/UserDetail.vue")
        // beforeEnter: requireAdmin()
      },
      {
        path: "sales",
        name: "sales",
        component: () => import("../views/admin/Sales.vue")
        // beforeEnter: requireAdmin()
      },
      {
        path: "/checkout/paymentDetail",
        name: "checkoutPaymentDetail",
        component: () => import("../views/checkout/PaymentDetail.vue")
        // beforeEnter: requireAdmin()
      },
      {
        path: "payments",
        name: "paymentMng",
        component: () => import("../views/admin/Payments.vue")
        // beforeEnter: requireAdmin()
      },
      {
        path: "paymentDetail",
        name: "adminPaymentDetail",
        component: () => import("../views/admin/PaymentDetail.vue"),
        props: { checkoutId: "" }
        // beforeEnter: requireAdmin()
      },
      {
        path: "demoList",
        name: "demos",
        component: () => import("../views/admin/Demos.vue")
        // beforeEnter: requireAdmin()
      },
      {
        path: "qna",
        name: "qna",
        component: () => import("../views/admin/Qna.vue")
        // beforeEnter: requireAdmin()
      },
      {
        path: "qnaManagement",
        name: "qnaManagement",
        component: () => import("../views/admin/QnaManagement.vue")
        // beforeEnter: requireAdmin()
      },
      {
        path: "/qnaReply",
        name: "qnaReply",
        component: () => import("../views/admin/QnaReply.vue")
        // beforeEnter: requireAdmin()
      }
    ]
  },
  // {
  //   path: "/admin/users",
  //   name: "users",
  //   component: () => import("../views/admin/Users.vue"),
  //   beforeenter: requireauth(),
  // },
  // {
  //   path: "/admin/usersDetail",
  //   name: "userDetail",
  //   component: () => import("../views/admin/UserDetail.vue"),
  //   beforeenter: requireauth(),
  // },
  // {
  //   path: "/admin/sales",
  //   name: "sales",
  //   component: () => import("../views/admin/Sales.vue"),
  //   beforeenter: requireauth(),
  // },
  // {
  //   path: "/admin/payments",
  //   name: "paymentMng",
  //   component: () => import("../views/admin/Payments.vue"),
  //   beforeenter: requireauth(),
  // },

  // {
  //   path: "/checkout/paymentDetail",
  //   name: "paymentDetail",
  //   component: () => import("../views/checkout/PaymentDetail.vue"),
  //   beforeenter: requireauth(),
  // },
  // {
  //   path: "/checkout/userPaymentDetail",
  //   name: "userPaymentDetail",
  //   component: () => import("../views/checkout/UserPaymentDetail.vue"),
  //   beforeenter: requireauth(),
  // },
  // {
  //   path: "/checkout/paymentInfo",
  //   name: "paymentInfo",
  //   component: () => import("../views/checkout/PaymentInfo.vue"),
  //   beforeenter: requireauth(),
  // },
  // {
  //   path: "/checkout/order",
  //   name: "order",
  //   component: () => import("../views/checkout/Order.vue"),
  //   beforeenter: requireauth(),
  // },
  {
    path: "/join",
    name: "join",
    component: () => import("../views/account/Join.vue")
  },
  {
    path: "/joinDone",
    name: "joinDone",
    component: () => import("../views/account/JoinDone.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/Login.vue"),
    beforeEnter: redirect()
  },
  {
    path: "/findEmail",
    name: "findEmail",
    component: () => import("../views/account/FindEmail.vue")
    // beforeEnter: redirect()
  },
  {
    path: "/findEmailDone",
    name: "findEmailDone",
    component: () => import("../views/account/FindEmailDone.vue")
    // beforeEnter: redirect()
  },
  {
    path: "/findPassword",
    name: "findPass",
    component: () => import("../views/account/FindPassword.vue")
  },
  {
    path: "/ChangePassword",
    name: "chgPwd",
    component: () => import("../views/account/ChangePassword.vue"),
    props: true
    // beforeEnter: redirect()
  },
  {
    path: "/resetPassword",
    name: "resetPwd",
    component: () => import("../views/account/ResetPassword.vue")
    // beforeEnter: redirect()
  },
  {
    path: "/mypage",
    name: "mypage",
    component: () => import("../views/Mypage.vue")
    // beforeEnter: requireAuth()
  },
  {
    path: "/paymentdetail",
    name: "paymentedetail",
    component: () => import("../views/PaymentDetail.vue")
    // beforeEnter: requireAuth()
  },
  {
    path: "/refund",
    name: "refund",
    component: () => import("../views/Refund.vue")
    // beforeEnter: requireAuth()
  },

  {
    path: "/checkout/paidDone",
    name: "paidDone",
    component: () => import("../views/checkout/PaidDone.vue")
    // beforeenter: requireauth(),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/terms.vue")
  },
  {
    path: "/company",
    name: "company",
    component: () => import("../views/about/Company.vue")
  },
  {
    path: "/culture",
    name: "culture",
    component: () => import("../views/about/Culture.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/about/Contact.vue")
  },
  {
    path: "/develop",
    name: "develop",
    component: () => import("../views/about/Develop.vue")
  },
  {
    path: "*",
    name: "*",
    component: () => import("../views/Error404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
