export default {
  language: "Language",
  join: "Sign up",
  join_title: "Sign Up",
  send: "Send",
  joinTerms: "Accept Terms of use",
  term: "Terms of use",
  joinPrivacy: "Accept Privacy Policy",
  privacy: "Privacy Policy",
  inputName: "Please, Enter your name.",
  inputPassword: "Please, Enter your password.",
  checkPassword: "Incorrect password.",
  checkNum: "Enter your Mobile number.",
  confirm: "Confirm",
  checkTerms: "Please, Agree the Terms.",
  checkPrivacy: "Please, Agree the Privacy Policy",
  joinCheck: "You are already a member.",
  requsetCertNum: "Please request your verification number again.",
  checkCertNum:
    "Incorrect the verification number. Please request the verification number again.",
  authOk: "It has been successfully authenticated.",
  sendCertNum: "The authorization number has been sent.",
  login: "Sign in",
  login_title: "Sign In",
  change_password: "Change Password",
  emailLogin: "Sign in with Email",
  facebookLogin: "Sign in with Facebook",
  googleLogin: "Sign in with Google",
  loginErrMsg: "The information you entered doesn't exist or doesn't match.",
  loginFail: "Invaild email or password",
  inputEmail: "Email",
  inputPwd: "Password",
  checkPwd: "Check password",
  joinPwd: "Password(over 6)",
  logout: "Sign out",
  findId: "Search ID",
  findId_mobile: "Mobile Number without '-'",
  findPwd: "Forgot Password?",
  mypage: "My Page",
  privatelegal: "Privacy Policy",
  email: "Email",
  password: "password",
  cellPhone: "Mobile Number",
  companyName: "Company Name",
  inputCompany: "Enter your company name",
  username: "Name",
  certNumber: "Certificate Number",
  noise: "Noise",
  reduction: "Reduction",
  price: "price",
  nr: "Noise Reduction",
  recording: "Recording",
  encoding: "Encoding",
  edit: "Edit",
  editing: "Editing",
  address: "Adress",
  serchAdd: "Search",
  web: "Web",
  logout_confirm: "Are you sure to logout ?",
  mypage_myinfo: "My Information",
  mypage_profile: "Profile",
  mypage_payment_management: "Payment History",
  mypage_qna_management: "QnA",
  mypage_charge: "Charge",
  mypage_payment: "Payment history",
  mypage_membership: "Membership",
  mypage_changePwd: "Change password",
  refund_reason_1: "Simple change of mind",
  refund_reason_2: "Order error",
  mypage_payment_paid: "Service paid",
  mypage_payment_info: "Payment information",
  mypage_payment_date: "Payment date",
  mypage_payment_amount: "Amount of payment",
  mypage_payment_nodata: "There is no purchase history.",
  mypage_member_sub: "Subscription status",
  total_review: "Total Review",
  phrase: {},
  product: "Product",
  product_title_1: "ReviewMind - AI Review Analytics & Marketing Platform",
  product_context_1:
    "Turn your customer reviews into better knowing your customers and your products, finally into smart business strategy by easily collecting and analyzing review contents with AI-powered ReviewMind",
  product_title_2: "AI-powered customer satisfaction analysis tool",
  product_context_2: "",
  product_context_2_li_1: "Monitor all your reviews in one screen easily",
  product_context_2_li_2:
    "Get sentiments and insights on your products from your customers' voice ",
  product_context_2_li_3:
    "Extract indeas to improve your products and services from your custom ideas",
  product_title_3: "Drive competitive edges of your products",
  product_context_3: "",
  product_context_3_li_1:
    "Surface most impactful competitive advantage/disadvantage of your products compared to other competing products",
  product_context_3_li_2:
    "Comparison analysis of customer experiences between your products and others'",
  product_context_3_li_3:
    "Manage your customers' experience and voices in smart ways",
  product_title_4: "Timely control your brand reputation",
  product_context_4: "",
  product_context_4_li_1:
    "Get notified as soon as a bad review is posted by automatic review analysis and SMS alerting service",
  product_context_4_li_2:
    "Improve your business reputation by identifying trends and deeper customer sentiments",
  product_context_4_li_3:
    "Response directly to every review by indentifying spam, slander, and other violations automatically",
  product_title_5: "Turn your customers into your marketing engine",
  product_context_5: "",
  product_context_5_li_1:
    "ReviewMind identify authenticity and marketing value on all your reviews ",
  product_context_5_li_2:
    "Automatically share your best reviews on your ads channels ",
  product_context_5_li_3:
    "Never miss a word of mouth from your customers so boost conversion rate",
  product_title_6: "REVIEWMIND",
  product_subtitle_6:
    "ReviewMind provides useful review by advanced AI text analysis technology",
  product_context_6_1: "Brand Analysis",
  product_context_6_2: "Marketing Strategy",
  product_context_6_3: "Measure Performance",
  product_context_6_4: "Issue Management",
  demo_request: "Review Your Customers' Mind with ReviewMind !",
  support: "Contact Support !",
  company: "Company",
  company_title_1: "Review Your Customers' Mind",
  company_context_1_1:
    "ReviewMind train AI-powerd NLP models to get insights and ideas",
  company_context_1_2:
    "on your products and services from your customers languages",
  company_context_1_3:
    "This is smart ways for your successful online business ",
  company_context_1_4: "with various data technologies of ReviewMind",
  company_title_2:
    "Get your competitive advantages in online business on the top of utilizing your customers' voice in a smart way",
  company_influence_title: "Avg. Online Influence",
  company_influence:
    "Reviews are especially important for local searches as they influence up to 10% of the ranking",
  company_sales_title: "Avg. sales",
  company_sales: "Reviews produce an average of 18% uplift in sales",
  company_conversion_title: "Avg. conversion",
  company_conversion:
    "Reviews of 50 or more per product can mean a 4.6% increase in conversion rate",
  company_title_3: "ReviewMind with AI-powered data technologies",
  company_title_3_1: "for your best review analytics and marketing supports",
  technology: "Technology",
  technology_title: "ReviewMind provides various AI-powerd data technologies,",
  technology_title_1:
    " especially on text-analytics knowhow with NLP (Natural Language Processing) technologies",
  technology_AI: "AI Technology",
  technology_AI_1: " (Machine Learning & Deep Learning)",
  technology_NLP: "NLP Text-analytics Technology",
  technology_NLP_1: "(Multi-language support)",
  technology_visualization: "Data Visualization",
  technology_visualization_1: "(Infographics)",
  technology_context_title: "Detail technology",
  technology_context_1: "Sentiment Analysis / Aspect-based Sentiment Analysis",
  technology_analysis: "Sentiment Analysis / Aspect-based Sentiment Analysis",
  technology_analysis_1: "",
  technology_analysis_text:
    "Identify the topics of conversion and evaluate, extract and quantify the emotional state or attitude of your customers towards those topics and a polarity score value",
  technology_topic: "Topic Modeling",
  technology_topic_1: "",
  technology_topic_text:
    "Unsupervised machine learning technique that's capable of scanning a set of documents, detecting word and phrase patterns within them, and automatically clustering word groups and similar expressions that best characterize a set of documents",
  technology_text: "Text Classification",
  technology_text_1: "",
  technology_text_text:
    "Assign a set of predefined categories to open-ended text. Text classifiers can be used to organize, structure, and categorize pretty much any kind of text – from documents, medical studies and files, and all over the web",
  technology_recognition: "Named Entity Recognition",
  technology_recognition_1: "",
  technology_recognition_text:
    "Extract the relevant multi-word noun, verb, adjective or adverbial phrase using morphological and syntactic analysis",
  technology_machine: "Machine Translation",
  technology_machine_1: "",
  technology_machine_text:
    "Automatic translation of text performed by a computer which provides text translations based on computer algorithms without human involvement",
  culture_main_title: "ReviewMind Eco-system",
  culture_title_1: "We do transparent and reasonable decision making",
  culture_title_context_1: "",
  culture_title_2: "We always grow together always",
  culture_title_context_2: "",
  culture_title_3: "We welcome to challenge - Grit",
  culture_title_context_3: "",
  culture_title_4: "We obsess over customer satisfaction ",
  culture_title_context_4: "",
  culture_title_5: "We win as a team",
  culture_title_context_5: "",
  culture_title_6: "We differentiate our technology experties",
  culture_title_context_6: "",
  member: "ReviewMind Members",
  member_context_1_1: "Seasonal Data Scientist",
  member_context_1_2: "Dreamer, Adventurer, Developer, and Finisher",
  member_context_2_1: "Enthusiastic Data Scientist",
  member_context_2_2: "Challenger, Innovator, and Fast Grower",
  member_context_3_university: "Kunsan National University",
  member_context_3_major: "Computer Informatics",
  member_context_3_1: "Positive Digital Marketer",
  member_context_3_2: "Creative, Passionate, and Hard working Professional",
  pricing_consulting: "Consulting Service",
  pricing_consulting_context_1:
    "Total review consulting service with collecting and analyzing your review and customer data",
  pricing_consulting_context_2:
    "(plus, one month free of ReviewMind Pro beta service)",
  pricing_sw: "SW Service",
  pricing_basic_1: "Review status",
  pricing_basic_2: "Review Insight",
  pricing_basic_3: "Reputation Management",
  pricing_basic_4: "Product Insight",
  pricing_basic_5: "Own Product 1",
  pricing_pro_1: "Competing Insight",
  pricing_pro_2: "Customer Insight",
  pricing_pro_3: "Review Marketing",
  pricing_pro_4: "Own Product 1 / Comparison product 4",
  pricing_premium_1: "Customization",
  pricing_premium_1_1: "",
  pricing_premium_2: "Support additional customized analytics and consulting",
  pricing_premium_3: "Mutual consultation",
  pricing_premium_4: "Consultation request",
  userTerm: "Terms of Use",
  privacyPolicy: "and Privacy Policy",
  inquiry: "Customer Inquiry",
  ER_inquiry: "Exchange Refund Inquiry",
  meet: "Meet ReviewMind",
  facebook: "Facebook",
  instagram: "Instargram",
  aboutCompany: "Sunny Mind Inc. | sunny Kim",
  companyAddress:
    "2F, KAC, 70, Haneul-gil, Gangseo-gu, Seoul, Republic of Korea",
  businessNumber: "Business Number : 837-86-02461",
  companyEmail: "Email : info@reviewmind.com",
  companyTel: "Tel : 02-717-5847",
  copyright:
    "Copyrightⓒ Sunny Mind Inc. All Rights Reserved. Powered by Sunny Mind Inc.",
  parking: "Paid Parking",
  contactUs: "Contact Us",
  partner_comment: "Let us join with your online business success!",
  sw_service: "SW Service",
  pricing_comment:
    "Up to 4 website URLs can be connected per product. If number of reviews exceeds more than 1000 per month, the number of products would be counted in thousands",
  review_status: "Current Review Status",
  review_insights: "Review Insights",
  product_insights: "Product Insights",
  competitive_insights: "Competitive Insights",
  customer_insights: "Customer Insights",
  review_Marketing: "Customer Marketing",
  reputation_management: "Reputation Management",
  negative_management: "Negative Review Status",
  average_ratings: "Average Ratings",
  period: "Period",
  whole_period: "Whole Period",
  last_12month: "last 12 Months",
  last_9month: "last 9 Months",
  last_6month: "last 6 Months",
  last_3month: "last 3 Months",
  total_reviews: "Total Reviews",
  best_key_factor: "Best Key Factor",
  reviews: "Reviews",
  satisfied: "Satisfied",
  neutral: "Neutral",
  dissatisfied: "Dissatisfied",
  see_reputation_management: "See Reputation Management",
  see_reputation_insight: "See Reputation Insight",
  see_review_insight: "See Review Insight",
  month_on_month: "Month on Month",
  last: "Last",
  negative_comment_1: "of the reviews in the last ",
  negative_comment_2: "months rated 3 stars or less",
  negative_comment_3: "months rated 3 stars or less",
  negative_comment_4: "개 입니다.",
  negative_comment_5: "of the reviews in the last ",
  negative_comment_6: "months rated negative sentiment score",
  no_data: "Result Not Found",
  review_summary: "Current Review Status Summary",
  level_satisfaction: "Level of Satisfaction",
  Reviews_StarTrends: "Reviews & Star Trends",
  set_topic: "Set Topic",
  use_marketing: "Use For Marketing",
  score: "Score",
  reset_review_select: "Reset Review Selection",
  save_image: "Save Image",
  select_review_frame: "Select Review Frame",
  review_marketing_preview: "Review Marketing Preview",
  review_marketing_comment:
    "Select the review you want to use for marketing from the Customer Insights menu",
  select_brand_color: "Select Brand Color",
  select_font_color: "Select font color",
  share: "Share",
  rectangular_type: "Rectangular Type",
  badge_type: "Badge Type",
  type_select: "type select",
  black: "Black",
  white: "White",
  gray: "Gray",
  preview_summary: "Preview Summary",
  select_comment:
    "Select the review you want to use for marketing from the Customer Insights menu.",
  dissatisfied_reviews_comment:
    "Dissatisfied Reviews / Low Star Rating Reviews",
  response_requirment_rate: "Response Requirment Rate",
  own_goods: "Own Goods",
  competitor_goods: "Competitor Goods",
  amazon: "Amazon",
  domestic: "Domestic",
  name: "Name",
  delete: "Delete",
  add: "Add",
  register_goods: "Register Goods",
  register_comp_goods: "Register Competitor Goods",
  noti: "notification",
  payment: "Payment",
  review_credibility: "Review Credibility",
  non_credible: "Non-Credible",
  credible: "Credible",
  review_source: "Review Source",
  review_sentence: "Review Sentences",
  comment_1: "You've got",
  comment_2: "reviews last",
  comment_3: "months",
  inputCert: "Enter certificate Number",
  five_point: "5 Point",
  four_point: "4 Point",
  three_point: "3 Point",
  two_point: "2 Point",
  one_point: "1 Point"
};
