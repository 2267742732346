import axios from "axios";
// import axios from "@/config/axiosConfig";
import { baseUrl, config } from "../config/env";
// axios.defaults.timeout = 0;

export function fetchReviewList(params) {
  return axios.get(
    baseUrl +
    `/review/list?page=${params.page}&date=${params.date}&nps=${params.nps}&rate=${params.rate}&source=${params.source}&term=${params.term}`,
    config
  );
}

export function fetchNegativeReviewList(params) {
  return axios.get(
    baseUrl +
    `/review/negative/list?page=${params.page}&sorting=${params.sorting}`,
    config
  );
}

export function fetchReviewRating(params) {
  return axios.get(
    baseUrl + `/review/rating?asin=${params.asin}&term=${params.term}`,
    config
  );
}

export function fetchReviewScore() {
  return axios.get(baseUrl + `/review/score`, config);
}

export function fetchReviewCount(params) {
  return axios.get(
    baseUrl +
    `/review/count?asin=${params.asin}&type=${params.type}&url=${params.url}`,
    config
  );
}

export function fetchProductInsight(params) {
  return axios.get(
    baseUrl + `/review/productInsight?asin=${params.asin}&term=${params.term}`,
    config
  );
}

export function fetchSentimentPred(params) {
  return axios.get(
    baseUrl + `/review/sentiment?asin=${params.asin}&term=${params.term}`,
    config
  );
}

export function fetchCompetitorSentimentPred(params) {
  return axios.get(
    baseUrl +
    `/review/competitor/sentiment?asin=${params.asin}&url=${params.url}&term=${params.term}`,
    config
  );
}

export function fetchCompetitiveFactors() {
  return axios.get(baseUrl + `/review/competitive/factor`, config);
}

export function fetchReviewInsight(params) {
  return axios.get(
    baseUrl + `/review/insight?asin=${params.asin}&term=${params.term}`,
    config
  );
}

export function fetchReviewStatus(params) {
  return axios.get(
    baseUrl + `/review/status?asin=${params.asin}&term=${params.term}`,
    config
  );
}

export function fetchNegativeRating(params) {
  return axios.get(
    baseUrl + `/review/negativeRatings?asin=${params.asin}&term=${params.term}`,
    config
  );
}

export function createModelData(data) {
  return axios.post(baseUrl + `/review/model`, data, config);
}

export function fetchCompetiteProdutFactor(params) {
  return axios.get(
    baseUrl +
    `/review/competite/product/insight?asin=${params.asin}&term=${params.term}`,
    config
  );
}

export function fetchReviews(params) {
  return axios.get(
    baseUrl +
    `/review/rating/reviews?asin=${params.asin}&url=${params.url}&term=${params.term}`,
    config
  );
}

export function fetchAsprectReviews(params) {
  return axios.get(
    baseUrl +
    `/review/absa/reviews?asin=${params.asin}&url=${params.url}&type=${params.type}&productName=${params.productName}`,
    config
  );
}
