<template>
  <header class="pageHeader" id="pageHeader">
    <div class="burger" @click="openMenu">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <nav id="nav">
      <a class="nav_top">
        <span class="nav_close" @click="closeMenu">
          <span class="material-icons">close</span>
        </span>
      </a>
      <div class="navBar">
        <ul class="mainMenu">
          <li @click="closeMenu">
            <a href="/">
              <img
                class="logo"
                src="@/assets/images/reviewmind/logo2.png"
                alt="logo"
              />
            </a>
            <a href="/">
              <img
                class="logo"
                src="@/assets/images/reviewmind/logo.png"
                alt="logo"
              />
            </a>
          </li>
          <!-- <li class="about_menu">
              <a @click="dropMenu()">About</a>
              <ul id="drop_menu">
                <router-link to="/company">회사소개</router-link>
                <router-link to="/">제품</router-link>
                <router-link to="/develop">기술</router-link>
                <router-link to="/culture">Culture</router-link>
                <router-link to="/contact">Contact</router-link>
              </ul>
          </li>-->
          <li @click="closeMenu">
            <router-link to="/" :class="{ active: gnbMenu == 1 }">
              {{ $t("product") }}
            </router-link>
          </li>
          <li @click="closeMenu">
            <router-link
              to="/company"
              alt="회사소개"
              :class="{ active: gnbMenu == 2 }"
              >{{ $t("company") }}</router-link
            >
          </li>

          <li @click="closeMenu">
            <router-link
              to="/develop"
              :class="{ active: gnbMenu == 3 }"
              alt="technology"
              >{{ $t("technology") }}</router-link
            >
          </li>
          <li @click="closeMenu">
            <router-link
              to="/culture"
              :class="{ active: gnbMenu == 4 }"
              alt="culture"
              >Culture</router-link
            >
          </li>
          <li @click="closeMenu">
            <router-link
              to="/pricing"
              :class="{ active: gnbMenu == 6 }"
              alt="pricing"
              >Pricing</router-link
            >
          </li>
          <li @click="closeMenu">
            <router-link
              to="/contact"
              :class="{ active: gnbMenu == 5 }"
              alt="contact"
              >Contact</router-link
            >
          </li>
          <!-- <li v-show="isLogin">
            <router-link to="/dashboard/reviewmind" alt="dashboard">Dashboard</router-link>
          </li>-->
          <li v-if="(isLogin && isPaid) || isAdmin">
            <router-link to="/dashboard/reviewmind" alt="dashboard"
              >Dashboard</router-link
            >
          </li>
        </ul>

        <ul class="dashMainmenu">
          <li class="dashLogo">
            <a href="/">
              <img
                src="@/assets/images/reviewmind/logo.png"
                class="logo1"
                alt="로고"
              />
            </a>
          </li>
          <li :class="{ active: dashboardMenu == 1 }">
            <router-link to="/dashboard/reviewmind">
              <img
                src="@/assets/images/reviewmind/nav-icon-1.png"
                alt="아이콘"
              />
              <img
                src="@/assets/images/reviewmind/nav-icon-11.png"
                alt="아이콘"
              />
              {{ $t("review_status") }}
            </router-link>
          </li>
          <li :class="{ active: dashboardMenu == 2 }">
            <router-link to="/dashboard/reviewmind2">
              <img
                src="@/assets/images/reviewmind/nav-icon-2.png"
                alt="아이콘"
              />
              <img
                src="@/assets/images/reviewmind/nav-icon-22.png"
                alt="아이콘"
              />
              {{ $t("review_insights") }}
            </router-link>
          </li>
          <li :class="{ active: dashboardMenu == 3 }">
            <router-link to="/dashboard/reviewmind3">
              <img
                src="@/assets/images/reviewmind/nav-icon-3.png"
                alt="아이콘"
              />
              <img
                src="@/assets/images/reviewmind/nav-icon-33.png"
                alt="아이콘"
              />
              {{ $t("product_insights") }}
            </router-link>
          </li>
          <li :class="{ active: dashboardMenu == 4 }">
            <router-link to="/dashboard/reviewmind4">
              <img
                src="@/assets/images/reviewmind/nav-icon-4.png"
                alt="아이콘"
              />
              <img
                src="@/assets/images/reviewmind/nav-icon-44.png"
                alt="아이콘"
              />
              {{ $t("competitive_insights") }}
            </router-link>
          </li>
          <li :class="{ active: dashboardMenu == 5 }">
            <router-link to="/dashboard/reviewmind5">
              <img
                src="@/assets/images/reviewmind/nav-icon-5.png"
                alt="아이콘"
              />
              <img
                src="@/assets/images/reviewmind/nav-icon-55.png"
                alt="아이콘"
              />
              {{ $t("customer_insights") }}
            </router-link>
          </li>
          <li :class="{ active: dashboardMenu == 6 }">
            <router-link to="/dashboard/reviewmind6">
              <img
                src="@/assets/images/reviewmind/nav-icon-6.png"
                alt="아이콘"
              />
              <img
                src="@/assets/images/reviewmind/nav-icon-66.png"
                alt="아이콘"
              />
              {{ $t("review_Marketing") }}
            </router-link>
          </li>
          <li :class="{ active: dashboardMenu == 7 }">
            <router-link to="/dashboard/reviewmind7">
              <img
                src="@/assets/images/reviewmind/nav-icon-7.png"
                alt="아이콘"
              />
              <img
                src="@/assets/images/reviewmind/nav-icon-77.png"
                alt="아이콘"
              />
              {{ $t("reputation_management") }}
            </router-link>
          </li>
        </ul>
        <TopBar />

        <ul class="rightMenu">
          <li class="watchDemo">
            <a href="/WatchDemo">
              Watch Demo
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li v-if="isAdmin">
            <a @click="move('users')">관리자</a>
          </li>

          <li v-if="isLogin">
            <a @click="handleProfile" alt="마이페이지">{{ $t("mypage") }}</a>
          </li>
          <li class="menuAlarm">
            <a alt="알림" @click="move('alarm')">Alarm</a>
            <span v-show="newMsg > 0" class="newMsg">{{ newMsg }}</span>
          </li>
          <li class="menuAlarm">
            <a alt="문의하기" @click="move('qna')">Q&#38;A</a>
          </li>
          <li>
            <a @click="handleLog()">
              {{ isLogin ? $t("logout") : $t("login") }}
            </a>
          </li>
          <li v-show="isLogin == false">
            <a @click="move('join')">{{ $t("join") }}</a>
          </li>

          <li class="notice" v-if="isLogin">
            <a @click="move('alarm')" alt="알람">
              <span class="material-icons">notifications_none</span>
              <span v-show="newMsg > 0" class="newMsg">{{ newMsg }}</span>
            </a>
          </li>
          <li class="notice" v-if="isLogin">
            <a @click="move('qna')" alt="문의하기">
              <span class="material-icons">support_agent</span>
            </a>
          </li>
          <li>
            <a @click="langChange()" alt="언어">
              <span>{{ currentLang === "ko" ? "EN" : "KR" }}</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <div id="nav_overlay"></div>
  </header>
</template>
<script>
// import axios from "axios"
import { mapState, mapActions } from "vuex";
import lang from "@/mixins/lang";
import { eventBus } from "../main";
import ClickOutside from "vue-click-outside";
import { fetchUserInfo, newAlarm } from "@/api/member";
import TopBar from "@/components/dashboard/TopBar";
export default {
  name: "Navbar",
  mixins: [lang],
  directives: {
    ClickOutside,
  },
  components: {
    TopBar,
  },
  data() {
    return {
      icon: "menu",
      menu: "메뉴보기",
      displayCenterModal: false,
      drawer: false,
      modalSize: "",
      visibleNav: false,
      popUp: false,
      alarmPopUp: false,
      serviceFlag: false,
      planYn: "",
      service: "",
      menuInfo: [],
      settingsInfo: [],
      messageListInfo: [],
      newMsg: "",
      username: null,
    };
  },
  computed: {
    ...mapState([
      "userInfo",
      "isLogin",
      "category",
      "isAdmin",
      "currentLang",
      "gnbMenu",
      "dashboardMenu",
      "isPaid",
    ]),
  },
  mounted() {
    eventBus.$on("closeModal", () => {
      this.closeModal();
    });
    this.newMsgCount();
    this.userInfomation();
  },
  methods: {
    ...mapActions(["LOGOUT"]),
    userInfomation() {
      fetchUserInfo().then((res) => {
        this.username = res.data.data.username;
        this.point = res.data.data.point;
      });
    },

    move(name, payload) {
      if (name == "qna") {
        this.$router
          .push({
            name: "mypage",
            query: { idx: "5" },
          })
          .catch(() => {});
      } else {
        this.$store.dispatch("GNB_MENU", payload);
        this.$router.push({ name: name });
        this.closeMenu();
      }
    },
    newMsgCount() {
      newAlarm().then((res) => {
        if (res.status == 200) {
          this.newMsg = res.data.alarmCount;
        } else {
          alert(res.data.message);
        }
      });
    },

    handleLog() {
      if (this.isLogin == true) {
        let result = confirm("로그아웃 하시겠습니까?");
        if (result) {
          if (this.userInfo.type == "KAKAO") {
            if (!window.Kakao.Auth.getAccessToken()) {
              return alert("토큰 값 없음");
            } else {
              window.Kakao.Auth.logout(() => {
                this.$store.dispatch("LOGOUT");
              });
            }
          } else if (this.userInfo.type == "GOOGLE") {
            let auth2 = window.gapi.auth2.getAuthInstance();
            auth2.signOut().then(() => {
              this.$store.dispatch("LOGOUT");
            });
          } else {
            this.$store.dispatch("LOGOUT");
          }
        }
      } else {
        this.$router.push({ name: "login" });
      }
      this.closeMenu();
    },

    closeModal() {
      this.displayCenterModal = false;
      this.drawer = false;
    },

    handleProfile() {
      if (this.isLogin == true) {
        this.$router.push({ name: "mypage" });
      } else {
        this.$router.push({ name: "login" });
      }
      this.closeMenu();
    },
    openMenu() {
      // window.document.getElementById("nav").style.display = "block";
      window.document.getElementById("nav").classList.add("nav__open");
    },
    closeMenu() {
      // window.document.getElementById("nav").style.display = "block";
      window.document.getElementById("nav").classList.remove("nav__open");
    },
  },
};
</script>
