import { fetchUserDetails } from "@/api/member";
import { fetchProductList } from "@/api/product";
const actions = {
  /**
   * LOGIN
   */
  LOGIN({ commit, state }, data) {
    commit("LOGIN", data);
    window.location.href = state.location
      ? state.location
      : // : "/dashboard/reviewmind";
      "/";
  },
  /*
   * LOGOUT
   */
  LOGOUT({ commit }) {
    localStorage.removeItem("accesstoken");
    commit("LOGOUT");
    window.location.href = "/";
  },

  /*
   * location
   * 로그인 후 이동 위치
   */
  LOCATION({ commit }, data) {
    commit("LOCATION", data);
  },

  /*
   * review 마켓팅을 위한 리뷰 데이터
   * review 배열값 전달
   */
  REVIEWDATA({ commit }, data) {
    commit("REVIEWDATA", data);
  },
  GNB_MENU({ commit }, data) {
    commit("GNB_MENU", data);
  },
  DASHBOARD_MENU({ commit }, data) {
    commit("DASHBOARD_MENU", data);
  },
  LOADING({ commit }, data) {
    commit("LOADING", data);
  },

  ISPAID({ commit }, data) {
    commit("ISPAID", data);
  },
  SET_PRODUCT({ commit }, data) {
    commit("SET_PRODUCT", data);
  },
  /**
   * 유저 정보를 가져온다.
   */
  getAccountInfo({ commit, dispatch }) {
    fetchUserDetails().then(res => {
      if (res.status == 200 && res.data.data) {
        let userInfo = {
          id: res.data.data.id,
          _id: res.data.data._id,
          point: res.data.data.point,
          isAdmin: res.data.data.isAdmin,
          address: res.data.data.address,
          birthday: res.data.data.birthday,
          email: res.data.data.email,
          username: res.data.data.username,
          mobileNo: res.data.data.mobileNo,
          isPaid: res.data.data.isPaid
        };
        commit("SET_USER_INFO", userInfo);
        commit("ISPAID", res.data.data.isPaid);
      } else {
        dispatch("LOGOUT");
      }
    });
  },
  getProductInfo({ commit }) {
    let data;
    fetchProductList().then(res => {
      if (res.data.status == 200) {
        let productList = res.data.data;
        productList.forEach(item => {
          if (item.isCompetitor == false) {
            data = {
              asin: item.asin,
              url: item.url,
              type: item.type,
              productName: item.productName
            };
          }
        });
        if (data) {
          commit("SET_PRODUCT", data);
        }
      }
    });
  },
  getLanguage({ commit }) {
    if (navigator.language == "ko-KR") {
      commit("langChange", "ko");
    } else {
      commit("langChange", "en");
    }
  },
  SET_NAVBAR({ commit }, data) {
    commit("SET_NAVBAR", data);
  },
  SET_FOOTER({ commit }, data) {
    commit("SET_FOOTER", data);
  },
  SET_USER_INFO({ commit }, data) {
    commit("SET_USER_INFO", data);
  },
  SET_ALERT({ commit }, data) {
    commit("SET_ALERT", data);
  },
  VIEW_TERM({ commit }, data) {
    commit("VIEW_TERM", data);
  },
  SET_ASIN({ commit }, data) {
    commit("SET_ASIN", data);
  },
  SET_URL({ commit }, data) {
    commit("SET_URL", data);
  }
};

export default actions;
