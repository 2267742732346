const state = {
  userInfo: {
    type: "",
    address: "",
    addressDetail: "",
    birthday: "",
    email: "",
    mobileNo: "",
    userIconPath: "",
    userImagePath: "",
    username: "",
    zipcode: "",
    id: null,
    merchant_uid: "",
    point: ""
  },
  asin: "",
  url: "",
  term: "",
  type: "",
  competitorList: [],
  username: "",
  isLogin: false,
  gnbMenu: 0,
  dashboardMenu: 0,
  isAdmin: false,
  isPaid: false,
  navVisible: true,
  footerVisible: true,
  isLoading: false,
  reviewData: [],
  currentLang: "ko",
  location: "",
  alert: {
    alertState: false,
    alertText: ""
  }
};

export default state;
