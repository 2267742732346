import axios from "@/config/axiosConfig";
import { baseUrl, header, config, headers } from "../config/env";
// axios.defaults.timeout = 120000000;

header["Content-Type"] = "multipart/form-data";
// header.accesstoken = localStorage.getItem("accesstoken");

export function createRegister(data) {
  return axios.post(baseUrl + "/accounts/join", data, { header });
}

export function createLogin(data) {
  return axios.post(baseUrl + "/accounts/login", data);
}

export function findUserEmail(data) {
  return axios.post(baseUrl + "/accounts/find/accountId", data);
}

export function findPassword(data) {
  return axios.post(baseUrl + "/accounts/findpass", data);
}

export function changePassword(data) {
  return axios.post(baseUrl + "/accounts/changepass", data, config);
}

export function vchangePassword(data) {
  return axios.post(baseUrl + "/accounts/vchangepass", data);
}

export function createPassword(data) {
  return axios.post(baseUrl + "/accounts/lostpass", data);
}

export function fetchUserList(params) {
  return axios.get(
    baseUrl +
    `/admin/userList?page=${params.page}&inputMode=${params.inputMode}&searchWord=${params.searchWord}`,
    config
  );
}

export function fetchUserMembership(merchant_uid) {
  return axios.get(baseUrl + `/accounts/membership/${merchant_uid}`, config);
}

export function fetchUserInfo() {
  return axios.get(baseUrl + `/accounts/profile`, config);
}

export function deleteUser(id) {
  return axios.delete(baseUrl + `/admin/usersInfo/${id}`, config);
}

export function fetchDemoList(params) {
  return axios.get(
    baseUrl +
    `/admin/demo/list?page=${params.page}&searchWord=${params.searchWord}&inputMode=${params.inputMode}&startYmd=${params.startYmd}&endYmd=${params.endYmd}&mobileNo=${params.mobileNo}`,
    config
  );
}

// export function fetchQnaList(params) {
//   return axios.get(
//     baseUrl +
//       `/admin/post/list?page=${params.page}&searchWord=${params.searchWord}&inputMode=${params.inputMode}&status=${params.status}`,
//     config
//   );
// }
export function fetchQnaList(params) {
  return axios.get(baseUrl + `/admin/post/list`, { params, headers });
}

export function fetchQnaDetails(id) {
  return axios.get(baseUrl + `/admin/post/${id}`, config);
}

export function createQnaReply(id, data) {
  return axios.post(baseUrl + `/admin/post/${id}/reply`, data, config);
}

export function updateQnaReply(data) {
  return axios.put(baseUrl + `/admin/post/reply`, data, config);
}

export function fetchUserDetails() {
  return axios.get(baseUrl + "/accounts/profile", config);
}

export function fetchUserDetail(id) {
  return axios.get(baseUrl + `/admin/userInfo/${id}`, config);
}

export function updateProfileInfo(data) {
  return axios.post(baseUrl + `/accounts/profile/update`, data, config);
}

export function updateUserInfo(data) {
  return axios.put(baseUrl + `/admin/usersInfo`, data, config);
}

export function createPoint(data) {
  return axios.post(baseUrl + `/checkout/point`, data, config);
}

export function fetchAddress(query) {
  let headers = {
    Authorization: "KakaoAK 08689db4862ca909dd7a847abdda6130"
  };
  return axios.get(
    `https://dapi.kakao.com/v2/local/search/address.json?query=${query}`,
    {
      header,
      config,
      headers
    }
  );
}

export function newAlarm() {
  return axios.get(baseUrl + `/message/alarmInfo`, config);
}

export function clickAlarm() {
  return axios.get(baseUrl + `/message/newMessage`, config);
}

export function verifyMobile(data) {
  return axios.post(baseUrl + `/auth/sms/verify`, data, config);
}

export function createCertNumber(data) {
  return axios.post(baseUrl + `/auth/sms`, data, config);
}

export function fetchUserDashboard() {
  return axios.get(baseUrl + `/admin/user/dashboard`, config);
}
