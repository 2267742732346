export const baseUrl = "https://api.reviewmind.com";
// export const baseUrl = "http://localhost:3000";
export const header = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  "X-Content-Type-Options": "nosniff",
  "X-XSS-Protection": "1",
  "Access-Control-Allow-Credentials": true,
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept, Authorization"
};
export const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Content-Type-Options": "nosniff",
  authorization: localStorage.getItem("accesstoken")
};

export const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("accesstoken")
  }
};
