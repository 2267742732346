import axios from "axios";
import http from "http";
import https from "https";
const httpAgent = new http.Agent({ keepAlive: true });
const httpsAgent = new https.Agent({ keepAlive: true });
// export const baseUrl = "https://api.reviewmind.com";
export const baseUrl = "http://localhost:3000";
const instance = axios.create({
  baseUrl,
  httpAgent,
  httpsAgent
});

instance.interceptors.response.use(
  response => {
    // http status가 200인 경우 응답 바로 직전에 대해 작성.
    return response;
  },
  error => {
    // http status가 200인 아닌 경우 응답 바로 직전에 대해 작성.
    window.console.log(error);
    return Promise.reject(error);
  }
);
export default instance;
