export default {
  language: "언어",
  join: "회원가입",
  join_title: "회원가입",
  send: "인증번호발송",
  joinTerms: "이용약관에 동의",
  term: "이용약관",
  joinPrivacy: "개인정보수집·이용 동의",
  privacy: "개인정보 수집 및 이용동의 약관",
  inputName: "이름을 입력해 주세요.",
  inputPassword: "비밀번호를 입력해 주세요.",
  checkPassword: "비밀번호가 다릅니다.",
  checkNum: "휴대폰 번호를 입력해주세요.",
  confirm: "인증완료",
  checkTerms: "이용약관에 동의 해주십시오.",
  checkPrivacy: "개인정보수집 및 이용에 동의 해주십시오.",
  joinCheck: "이미 회원 가입 하였습니다.",
  requsetCertNum: "인증번호를 다시 요청해주세요.",
  checkCertNum:
    "인증번호가 잘못 되었습니다.다시 인증번호를 다시 확인 해주세요.",
  authOk: "정상적으로 인증이 되었습니다.",
  sendCertNum: "인증번호가 발송 되었습니다.",
  login: "로그인",
  login_title: "로그인",
  emailLogin: "이메일 로그인",
  facebookLogin: "페이스북 로그인",
  googleLogin: "구글 로그인",
  loginErrMsg: "입력한 정보가 없거나 일치하지 않습니다.",
  loginFail: "등록되어 있지 않은 이메일 입니다.",
  inputEmail: "이메일 입력",
  inputPwd: "비밀번호 입력",
  checkPwd: "비밀번호 확인",
  joinPwd: "비밀번호 (6자 이상)",
  logout: "로그아웃",
  findId: "아이디 찾기",
  change_password: "비밀번호 변경",
  findId_mobile: "휴대폰 번호 입력(-없이 입력)",
  findPwd: "비밀번호 찾기",
  mypage: "마이페이지",
  privatelegal: "개인 정보처리 동의방침",
  email: "이메일",
  password: "비밀번호",
  cellPhone: "휴대폰번호",
  companyName: "회사명",
  inputCompany: "회사명을 입력해주세요",
  username: "이름",
  certNumber: "인증번호",
  pricing: "가격",
  address: "주소",
  serchAdd: "주소 검색",
  web: "웹",
  audio: "오디오",
  logout_confirm: "로그아웃 하시겠습니까 ?",
  mypage_myinfo: "나의 정보",
  mypage_profile: "회원 정보",
  mypage_payment_management: "결제 관리",
  mypage_qna_management: "나의 문의사항 관리",
  mypage_membership: "나의 멤버십",
  mypage_charge: "충전하기",
  mypage_changePwd: "비밀번호변경",
  refund_reason_1: "단순변심",
  refund_reason_2: "주문실수",
  mypage_payment_paid: "결제한 서비스",
  mypage_payment_info: "결제 정보",
  mypage_payment_date: "결제일",
  mypage_payment_amount: "결제금액",
  mypage_payment_nodata: "구매내역이 없습니다.",
  mypage_member_sub: "가입한 멤버십",
  total_review: "총 리뷰 현황",
  phrase: {},
  product: "제품",
  product_title_1: "AI 리뷰 분석 & 마케팅 플랫폼",
  product_context_1:
    "리뷰마인드는 최신 AI 및 텍스트 분석 기술(NLP)을 활용하여, 제품 및 서비스에 대한 AI 리뷰 분석 & 마케팅 플랫폼 기반 컨설팅을 제공합니다.",
  product_title_2: "인공지능 기반의 제품 만족도 분석",
  product_context_2:
    "- AI 기반 텍스트 분석을 통해 고객 만족도 이해와 제품 전략 수립 지원",
  product_context_2_li_1: "전체 리뷰별 자동 감성 분석",
  product_context_2_li_2: "제품 세부항목별 감성 분석",
  product_context_2_li_3: "제품 개선 및 신제품 개발 아이디어 추출",
  product_title_3: "경쟁 제품과의 경쟁우위 요인 분석",
  product_context_3:
    "- 경쟁 제품들과의 리뷰 분석내용 비교를 통하여 경쟁 제품 대비 자사 제품/서비스가 지닌 우위 또는 열위 요소를 포착",
  product_context_3_li_1: "경쟁 제품들과의 리뷰 현황 비교 분석",
  product_context_3_li_2: "경쟁 제품들과의 제품 세부항목별 감성분석 비교",
  product_context_3_li_3: "자사 제품의 우위 또는 열위 요인 포착 지원",
  product_title_4: "부정적 리뷰의 모니터링 및 SMS 알림",
  product_context_4:
    "- 리뷰 내용의 자동 분석 기능을 통해 악성, 부정적 리뷰의 신속한 처리 지원",
  product_context_4_li_1: "악성, 부정적 리뷰 자동 분류 분석",
  product_context_4_li_2: "악성, 부정적 리뷰가 달리는 즉시 SMS 알림 제공",
  product_context_4_li_3: "악성, 부정적 리뷰 유형별 대응 가이드 제공",
  product_title_5: "리뷰 신뢰성 자동 판별 분석 및 마케팅 활용 지원",
  product_context_5:
    "- AI 기반의 텍스트 분석을 통해 내용없는 도배성 리뷰와 유용한 진짜 리뷰를 자동 분류 ",
  product_context_5_li_1:
    "비즈니스 활용에 유용한 신뢰성 있는 리뷰 자동 분류 분석",
  product_context_5_li_2: "고객 추천지수 분석으로 구매자극 리뷰들을 자동 추출 ",
  product_context_5_li_3: "구매자극 리뷰들을 활용한 리뷰 마케팅 기능 지원",
  product_title_6: "REVIEWMIND",
  product_subtitle_6:
    "리뷰마인드가 최적의 AI 및 텍스트 분석 기술을 이용하여 여러분의 베스트 리뷰 활용을 지원합니다.",
  product_context_6_1: "브랜드 분석",
  product_context_6_2: "마케팅 전략",
  product_context_6_3: "성과 측정",
  product_context_6_4: "이슈 관리",
  demo_request: "온라인 비즈니스의 성공, 리뷰마인드와 함께 하세요!",
  support: "기술 문의 환영",
  company: "회사소개",
  company_title_1: "고객의 마음을 가장 잘 아는 서비스",
  company_context_1_1:
    "리뷰마인드는 최신 AI 및 텍스트 분석 기술(NLP)을 활용하여,",
  company_context_1_2:
    "제품 및 서비스에 대한 고객의 언어 (User-generated Contents) 속에",
  company_context_1_3: "숨겨져 있는 비즈니스 인사이트를 분석하여 제공함으로써,",
  company_context_1_4: "온라인 비즈니스의 성공을 지원합니다.",
  company_title_2:
    "적극적인 고객 리뷰 활용으로 온라인 비즈니스의 경쟁력을 확보하세요",
  company_influence_title: "평균 온라인 인지도",
  company_influence: "리뷰를 통한 평균 온라인인지도 10% 증가",
  company_sales_title: "평균 매출",
  company_sales: "리뷰는 평균 18%의 매출 상승효과 생성",
  company_conversion_title: "평균 전환율",
  company_conversion: "제품당 50개 이상 리뷰는 전환율 4.6% 제고 효과",
  company_title_3: "온라인 판매량을 결정짓는 고객들의 리뷰,",
  company_title_3_1: "리뷰의 가장 효과적인 활용을 리뷰마인드가 지원합니다.",
  technology: "기술",
  technology_title:
    "리뷰마인드는 AI & NLP 기반 다양한 Text Analytics 기술들을 보유하고 있습니다.",
  technology_title_1: "",
  technology_AI: "AI기술",
  technology_AI_1: "(머신러닝 & 딥러닝)",
  technology_NLP: "NLP 텍스트 분석 기술",
  technology_NLP_1: "(다국어 지원)",
  technology_visualization: "데이터 시각화",
  technology_visualization_1: "(인포 그래픽스)",
  technology_context_title: "상세 기술",
  technology_context_1: "",
  technology_analysis: "감성 분석",
  technology_analysis_1:
    "(Sentiment Analysis / Aspect-based Sentiment Analysis)",
  technology_analysis_text:
    "리뷰에 담긴 고객의 언어에서 말하고자 하는 주제 요인들을 파악하여 해당 요인에 대한 고객의 감성과 태도, 의견을 분석하고 판별 측정하는 기술",
  technology_topic: "토픽 분류 모델링",
  technology_topic_1: "(Topic Modeling)",
  technology_topic_text:
    "빅데이터 비정형 텍스트 문서에서 언급하고 있는 주제 토픽의 비율을 산출해 내는 기술 ",
  technology_text: "텍스트 분류",
  technology_text_1: "(Text Classification)",
  technology_text_text:
    "다양한 비즈니스 목적에 맞게 모델을 설계하여 비정형 텍스트 데이터 문서를 분류 분석하는 기술",
  technology_recognition: "개체명 인식",
  technology_recognition_1: "(Named Entity Recognition)",
  technology_recognition_text:
    "형태소 및 구문 분석을 활용하여 관련 주제별 다수 단어로 이루어진 명사, 동사, 형용사, 및 부사 구문을 추출해 내는 기술",
  technology_machine: "기계 번역",
  technology_machine_1: "(Machine Translation)",
  technology_machine_text:
    "AI 기술을 이용하여 서로 다른 언어 간 자동으로 번역하는 기술",
  culture_main_title: "리뷰마인드 에코-시스템 (ReviewMind Eco-system)",
  culture_title_1: "투명하고 합리적인 의사결정",
  culture_title_context_1:
    "모든 정보는 정직하고 투명하게 공유되고,그에 기반하여 합리적인 의사결정을 합니다.",
  culture_title_2: "화사와 직원의 동반 성장",
  culture_title_context_2: "회사의 성장과 나의 성장은 한 방향으로 같습니다.",
  culture_title_3: "그릿(Grit)- 성장을 위한 열정",
  culture_title_context_3:
    "문제는 해결을 위해 존재하므로, 문제해결 과정을 두려워하지 않고 성장의 기회로 삼습니다.",
  culture_title_4: "고객 만족에 대한 집착",
  culture_title_context_4:
    "고객이 필요로 하는 니즈에 시원하고 명쾌한 서비스로 답하는데 있어 집요합니다.",
  culture_title_5: "이기는 팀의 환희",
  culture_title_context_5:
    "승리와 성공의 기쁨은 함께 최선을 다해 일하고, 함께 이루어냈을 때 가장 큽니다.",
  culture_title_6: "AI 및 NLP(자연어처리) 기술분야 전문 기업",
  culture_title_context_6:
    "최신의 기술력과 남다른 서비스에 대한 집중력으로 월등히 차별화된 기술 기반 서비스를 만들어 냅니다.",
  member: "리뷰마인드 멤버 소개",
  member_context_1_1: "데이터 분석 및 사이언스 경력 10년 이상",
  member_context_1_2: "꿈꾸는 자, 모험가, 창작자, 그리고 이루는 자",
  member_context_2_1: "데이터 사이언티스트 인턴 경력",
  member_context_2_2: "도전하는 자, 혁신가, 그리고 늘 발전하는 자",
  member_context_3_university: "군산대학교",
  member_context_3_major: "컴퓨터정보학과 전공",
  member_context_3_1: "디지털 마케팅 경력 3년",
  member_context_3_2: "크리에이터, 열정가, 그리고 묵묵히 치밀한 자",
  pricing_consulting: "컨설팅 서비스",
  pricing_consulting_context_1:
    "개별 고객 요건별 토탈 온라인쇼핑 리뷰데이터 수집 및 스마트 분석 컨설팅 서비스 제공 + 리뷰마인드 Pro 서비스(Beta 버전) 한달 무료 이용권",
  pricing_consulting_context_2:
    "협의 요망(단, 분석 대상 리뷰수에 따라 컨설팅 수수료가 달라질 수 있습니다. 전화 문의 요망)",
  pricing_sw: "SW 서비스",
  pricing_basic_1: "리뷰 현황",
  pricing_basic_2: "리뷰 인사이트",
  pricing_basic_3: "리뷰 평판 관리",
  pricing_basic_4: "제품 인사이트",
  pricing_basic_5: "자사제품 1",
  pricing_pro_1: "경쟁 인사이트",
  pricing_pro_2: "고객 인사이트",
  pricing_pro_3: "리뷰 마케팅",
  pricing_pro_4: "자사제품 1 / 비교제품 4",
  pricing_premium_1: "브랜드/제품 카테고리별, 기간별",
  pricing_premium_1_1: "Customization",
  pricing_premium_2: "추가 분석 기능 지원",
  pricing_premium_3: "상호협의",
  pricing_premium_4: "협의요망",
  userTerm: "이용약관 및",
  privacyPolicy: "개인정보취급방침",
  inquiry: "고객문의",
  ER_inquiry: "교환/환불 문의",
  meet: "리뷰마인드를 만나보세요",
  facebook: "페이스북",
  instagram: "인스타그램",
  aboutCompany: "(주)써니마인드 | 김선희",
  companyAddress: "서울특별시 강서구 하늘길 70 KAC 항공지원센터 2층",
  businessNumber: "사업자등록번호 : 837-86-02461",
  companyEmail: "이메일 : info@reviewmind.com",
  companyTel: "전화번호 : 02-717-5847",
  copyright:
    "Copyrightⓒ (주)써니마인드. All Rights Reserved. Powered by (주)써니마인드",
  parking: "유료주차",
  contactUs: "서비스 문의하기",
  partner_comment: "온라인 비즈니스의 성공, 리뷰마인드와 함께 하세요!",
  sw_service: "SW 서비스",
  pricing_comment:
    "제품당 판매사이트 url 5개까지 연결 가능, 월 리뷰수 1천개 이상일 경우 - 월 1천개 리뷰수 단위별로 제품수 계상",
  review_status: "리뷰 현황",
  review_insights: "리뷰 인사이트",
  product_insights: "제품 인사이트",
  competitive_insights: "경쟁 인사이트",
  customer_insights: "고객 인사이트",
  review_Marketing: "리뷰 마케팅",
  reputation_management: "리뷰 평판관리",
  negative_management: "부정적인 리뷰 현황",
  average_ratings: "총 별점 평균",
  period: "기간",
  whole_period: "전체 기간",
  last_12month: "지난 12개월",
  last_9month: "지난 9개월",
  last_6month: "지난 6개월",
  last_3month: "지난 3개월",
  total_reviews: "총 리뷰 수",
  best_key_factor: "베스트 경쟁 요인",
  reviews: "리뷰",
  satisfied: "만족해요",
  neutral: "보통",
  dissatisfied: "별로에요",
  see_reputation_management: "리뷰 평판관리 보기",
  see_review_insight: "리뷰 인사이트 보기",
  month_on_month: "전달 대비",
  last: "지난",
  negative_comment_1: "개월동안 받은 리뷰",
  negative_comment_2: "개 중, 별점 3점 이하를 받은 리뷰가",
  negative_comment_3: "점 이하를 받은 리뷰가",
  negative_comment_4: "개 입니다.",
  negative_comment_5: "개월동안 감성 점수 Nagetive를 받은 리뷰가",
  negative_comment_6: "개 입니다.",
  no_data: "데이터가 없습니다.",
  review_summary: "리뷰 현황 요약",
  level_satisfaction: "고객 만족도",
  Reviews_StarTrends: "리뷰 & 별점 트랜드",
  set_topic: "TOPIC 재설정",
  use_marketing: "리뷰 마케팅 활용",
  score: "점수",
  reset_review_select: "리뷰 선택 재설정",
  select_review_frame: "리뷰 프레임 선택",
  save_image: "이미지로 저장",
  review_marketing_preview: "리뷰 마케팅 프리뷰",
  review_marketing_comment:
    "고객 인사이트 메뉴에서 마케팅에 활용할 리뷰를 선택해 주세요.",
  select_brand_color: "브랜드 컬러 선택",
  select_font_color: "폰트컬러 선택",
  share: "공유하기",
  rectangular_type: "사각형",
  badge_type: "벳지형",
  type_select: "타입 선택",
  black: "검정색",
  white: "흰색",
  gray: "회색",
  preview_summary: "리뷰 요약",
  select_comment:
    "고객 인사이트 메뉴에서 마케팅에 활용할 리뷰를 선택해 주세요.",
  dissatisfied_reviews_comment: "불만족한 리뷰 / 낮은 별점 리뷰",
  response_requirment_rate: "대체 필요지수",
  own_goods: "자사 제품",
  competitor_goods: "경쟁사 제품",
  amazon: "아마존",
  domestic: "네이버",
  name: "제품명",
  delete: "삭제",
  add: "추가",
  register_goods: "제품 등록",
  register_comp_goods: "경쟁사 제품 등록",
  noti: "알림",
  payment: "결제내역",
  review_credibility: "리뷰 신뢰지수",
  five_point: "5점",
  four_point: "4점",
  three_point: "3점",
  two_point: "2점",
  one_point: "1점",
  non_credible: "Non-Credible",
  credible: "Credible",
  review_source: "Review Source",
  review_sentence: "리뷰 문장",
  comment_1: "지난 개월 동안",
  comment_2: "개의 리뷰를 받았습니다.",
  comment_3: "",
  inputCert: "인증번호를 입력해 주세요."
};
