<template>
  <div class="floatAlert">
    <p>{{alert.alertText}}</p>
    <a href="javascript:void(0);" class="btnCancel">닫기</a>
    <a href="javascript:void(0);" class="material-icons btnClose" @click="handleClose">close</a>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  // props: ["text"],
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      let data = {
        alertText: "",
        alertState: false
      };
      this.$store.dispatch("SET_ALERT", data);
    }, 4000);
  },
  computed: {
    ...mapState(["alert"])
  },
  methods: {
    handleClose() {
      let data = {
        alertText: "",
        alertState: false
      };
      this.$store.dispatch("SET_ALERT", data);
    }
  }
};
</script>