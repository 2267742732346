import axios from "axios";
import { baseUrl, config, headers } from "../config/env";

export function uploadFile(data) {
  return axios.post(baseUrl + `/file/upload`, data, config);
}

export function deleteFile(path) {
  return axios.get(baseUrl + `/file/delete?path=${path}`, config);
}

export function createWorkOrder(branchId, data) {
  return axios.post(baseUrl + `/workorder/register/${branchId}`, data, config);
}

export function createBasket(data) {
  return axios.post(baseUrl + `/basket/register`, data, config);
}

export function deleteBasket(id) {
  return axios.get(baseUrl + `/basket/delete/${id}`, config);
}

export function updateBasket(data) {
  return axios.post(baseUrl + `/basket/update`, data, config);
}

export function createCheckout(data) {
  return axios.post(baseUrl + `/checkout/payments/register`, data, config);
}

export function cancelSubscribe() {
  return axios.get(baseUrl + `/checkout/subscribe/cancel`, config);
}

export function createSubscribe(data) {
  return axios.post(
    baseUrl + `/checkout/subscriptions/issue-billing`,
    data,
    config
  );
}

// export function createCheckoutByBankPayment(data) {
//   return axios.post(baseUrl + `/checkout/payments/register`, data, config);
// }
export function createFacebookLogin(data) {
  return axios.post(baseUrl + "/auth/facebook", data);
}
export function deleteCheckout(id) {
  return axios.get(baseUrl + `/checkout/payments/delete/${id}`, config);
}
// export function getCheckoutComplete(params) {
//   return axios.get(
//     baseUrl + `/checkout/complete?imp_uid=${params.imp_uid}`,
//     config
//   );
// }
export function getCheckoutComplete(params) {
  return axios.get(baseUrl + `/checkout/complete`, { params, headers });
}

export function getCheckoutCompleteByBankPayment() {
  return axios.get(baseUrl + `/checkout/bank/complete`, config);
}
export function fetchPaymentDetail(params) {
  return axios.get(baseUrl + `/checkout/payments/${params.imp_uid}`, config);
}

export function fetchPaymentDetailByVbank(params) {
  return axios.get(baseUrl + `/checkout/payments/vbank/${params.id}`, config);
}

export function fetchPayment(params) {
  return axios.get(
    baseUrl +
    `/checkout/payments/find/${params.merchant_uid}/${params.payment_status}`,
    config
  );
}

export function fetchPayments(params) {
  return axios.get(
    baseUrl +
    `/checkout/payments/findAll/${params.merchant_uid}/${params.payment_status}`,
    config
  );
}

// export function fetchUserPayments(params) {
//   return axios.get(
//     baseUrl + `/checkout/payments/user?page=${params.page}`,
//     config
//   );
// }

export function fetchPaymentsByStatus(status) {
  return axios.get(baseUrl + `/checkout/payments/status/${status}`, config);
}

export function fetchDemoList(params) {
  return axios.get(
    baseUrl +
    `/admin/demo/list?page=${params.page}&searchWord=${params.searchWord}&inputMode=${params.inputMode}&startYmd=${params.startYmd}&endYmd=${params.endYmd}&mobileNo=${params.mobileNo}`,
    config
  );
}

export function checkPaymentsByStatus(params) {
  return axios.get(
    baseUrl +
    `/checkout/payments/list?page=${params.page}&searchWord=${params.searchWord}&inputMode=${params.inputMode}&startYmd=${params.startYmd}&endYmd=${params.endYmd}`,
    config
  );
}

export function refundState(id, data) {
  return axios.post(baseUrl + `/checkout/refund/state/${id}`, data, config);
}

export function makingOrder(id) {
  return axios.get(baseUrl + `/checkout/making/order/${id}`, config);
}

export function createRefund(data) {
  return axios.post(baseUrl + `/checkout/iamport/refund`, data, config);
}

export function deletePaymentHistory(id) {
  return axios.get(baseUrl + `/checkout/payments/delete/${id}`, config);
}

export function requestDemo(data) {
  return axios.post(baseUrl + "/accounts/requestDemo", data, config);
}

export function verifyMobile(data) {
  return axios.post(baseUrl + `/auth/sms/verify`, data, config);
}

export function createCertNumber(data) {
  return axios.post(baseUrl + `/auth/sms`, data, config);
}

//포인트 보유 검증
export function verifyPointDeduction(data) {
  return axios.post(baseUrl + `/accounts/point/verify`, data, config);
}
//포인트 차감
export function createPointDeduction(data) {
  return axios.post(baseUrl + `/accounts/point/deduction`, data, config);
}

// 결제내역 조회
export function fetchUserCharges(params) {
  return axios.get(baseUrl + `/checkout/payments/charges`, { params, headers });
}
// 취소내역 조회
export function fetchUserdeductions(params) {
  return axios.get(baseUrl + `/checkout/payments/deductions`, {
    params,
    headers
  });
}
// point 내역 조회
export function fetchPointList(params) {
  return axios.get(baseUrl + `/accounts/point/list`, { params, headers });
}
// // 차감내역 조회
// export function fetchPointdeductions(params) {
//   return axios.get(baseUrl + `/checkout/payments/deductions`, { params, headers });
// }

// 유저 결제 정보
export function fetchUserCheckoutDetail(params) {
  return axios.get(baseUrl + `/checkout/payments/checkoutDetail`, {
    params,
    headers
  });
}

// 유저 환불 신청
export function refundRequest(data) {
  return axios.put(baseUrl + `/checkout/refund/request`, data, { headers });
}

// 유저 환불 거절
export function updateRefundApproval(data) {
  return axios.put(baseUrl + `/checkout/refund/approval`, data, { headers });
}

// 유저 포인트 검사
export function userPointCheck(params) {
  return axios.get(baseUrl + `/checkout/user/point`, { params, headers });
}

// 유저 환불 승인
export function updateRefundAgree(data) {
  return axios.put(baseUrl + `/checkout/refund/agree`, data, { headers });
}
