import axios from "@/config/axiosConfig";
import { baseUrl, header, config } from "../config/env";

header["Content-Type"] = "multipart/form-data";

export function createProduct(data) {
  return axios.post(baseUrl + "/product/register", data, config);
}
export function updateProduct(id, data) {
  return axios.put(baseUrl + `/product/register/${id}`, data, config);
}

export function updateProductByAdmin(id, data) {
  return axios.post(baseUrl + `/admin/product/update/${id}`, data, config);
}

export function fetchProductList() {
  return axios.get(baseUrl + `/product/productList`, config);
}

export function deleteProduct(id) {
  return axios.delete(baseUrl + `/product/${id}`, config);
}

export function deleteProduct11() {
  return axios.delete(baseUrl + `/product/de`, config);
}

export function deleteCompetitorProduct(id) {
  return axios.delete(baseUrl + `/product/competitor/${id}`, config);
}
export function fetchProducts(params) {
  return axios.get(
    baseUrl +
    `/admin/productList?page=${params.page}&searchWord=${params.searchWord}&category=${params.category}`,
    config
  );
}

export function fetchProductInfo(params) {
  return axios.get(
    baseUrl +
    `/product/detail/${params.id}?page=${params.page}&searchWord=${params.searchWord}`,
    { header }
  );
}
