import "babel-polyfill";
import "es6-promise/auto";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/common.scss";
// import "expose-loader?$!expose-loader?jQuery!jquery";
// window.$ = window.jQuery = require("jquery");
import ClickOutside from "vue-click-outside";
import VueAwesomeSwiper from "vue-awesome-swiper";
import moment from "moment";
import "swiper/dist/css/swiper.css";
import "element-ui/lib/theme-chalk/index.css";
import Alert from "@/components/globalComponents/Alert";
import VueDaumPostcode from "vue-daum-postcode";
// import VueGtag from "vue-gtag";
import { KAKAO_KEY } from "@/config/key";
import ElementUI from "element-ui";
import i18n from "./lang";
import { Collapse, InfiniteScroll } from "element-ui";
import VueSocialSharing from "vue-social-sharing";
import Meta from "vue-meta";
// import Cookies from "js-cookie";

export const eventBus = new Vue();

Vue.config.productionTip = false;
Vue.use(VueSocialSharing);
window.Kakao.init(KAKAO_KEY); //카카오아이디로 로그인 javascript key
Vue.use(VueDaumPostcode);
Vue.use(eventBus);
Vue.use(moment);
Vue.use(ClickOutside);
// Vue.use(Drawer);
Vue.use(InfiniteScroll);
Vue.use(Collapse);
Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper);
// Vue.use(VueGtag, {
//   config: {
//     id: "G-TDKNY0CSFX", // Google Analytics의 Tracking ID를 넣어준다
//   },
// });
Vue.use(Meta);

Vue.component("Alert", Alert);
new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    if (this.$store.state.isLogin) {
      this.$store.dispatch("getAccountInfo");
      this.$store.dispatch("getProductInfo");
    }
    // this.$store.dispatch("getLanguage");
  },
  render: h => h(App)
}).$mount("#app");
