import Vuex from "vuex";
import Vue from "vue";
import state from "./state";
// import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});

const { accesstoken } = localStorage;
if (accesstoken) {
  store.commit("LOGIN", { accesstoken });
}
export default store;
